import { Heading } from "../shared/Heading";
import emailjs from "emailjs-com";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Footer from "./Footer";
import trucks from "../img/test1.jpg";
import { useTranslation } from "react-i18next";
import email from "../img/mail.png";
import phone from "../img/phone.png";
import location from "../img/location.png";
import test from "./../img/test2.jpg";

export default function ContactPage() {
  const { t } = useTranslation();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lxk2xwy",
        "template_3d5imui",
        e.target,
        "user_UkcGcvqdiA2OEDcVHThy2"
      )
      .then(
        (result) => {
          toast.success(t("Email is sent successfully!"));
        },
        (error) => {
          //console.log(error);
          toast.error(t("Something went wrong!"));
        }
      );

    e.target.reset();
  };

  return (
    <div className="bg-[#F6F5F5]">
      <ToastContainer closeButton={false} />
      <div as="header" className="relative z-10">
        <Heading />
        <div className="relative overflow-hidden">
          {/* Decorative background image and gradient */}
          <div aria-hidden="true" className="absolute inset-0">
            <div className="absolute inset-0 mx-auto overflow-hidden">
              <img
                src={test}
                alt=""
                className="w-full h-full object-center object-cover"
                style={{ marginTop: "-120px" }}
              />
            </div>
            <div className="absolute inset-0 bg-white bg-opacity-75" />
            <div className="absolute inset-0 bg-gradient-to-t from-white via-white" />
          </div>

          {/* Sale */}
          <section
            aria-labelledby="sale-heading"
            className="relative max-w-7xl mx-auto py-32 px-4 flex flex-col items-center text-center sm:px-6 lg:px-8"
          >
            <div className="max-w-2xl mx-auto lg:max-w-none">
              <h2
                id="sale-heading"
                className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl"
                style={{
                  color: "rgb(18 57 91)",
                  //color: "rgb(79 70 229/var(--tw-text-opacity))",
                }}
              >
                {t("Get in touch")}
              </h2>
              <div className="mt-6 max-w-3xl mx-auto text-xl leading-normal text-gray-500 text-center">
                <div className="flex justify-center	pb-5">
                  <div className="">
                    <img
                      className="h-5 w-5"
                      src={location}
                      alt="location image"
                    />
                  </div>
                  <div style={{ marginTop: "-5px", marginLeft: "5px" }}>
                    {t("Plovdiv 4004 Kuklensko shose 7A")}
                  </div>
                </div>

                <div className="lg:flex lg:justify-evenly">
                  <div className="">
                    <div className="flex">
                      <div>
                        <img
                          className="h-5 w-5"
                          src={email}
                          alt="email image"
                        />
                      </div>
                      <div style={{ marginTop: "-5px", marginLeft: "5px" }}>
                        krasiautotrans@gmail.com
                      </div>
                    </div>
                    <div className="flex">
                      <img className="h-5 w-5" src={email} alt="email image" />
                      <div style={{ marginTop: "-5px", marginLeft: "5px" }}>
                        office@krasiautotrans.com
                      </div>
                    </div>
                  </div>
                  <div style={{ paddingLeft: "70px" }}>
                    {/**here */}
                    <div className="flex">
                      <img className="h-5 w-5" src={phone} alt="phone image" />
                      <div style={{ marginTop: "-5px", marginLeft: "5px" }}>
                        +359 89 842 9008
                      </div>
                    </div>
                    <div className="flex">
                      <img className="h-5 w-5" src={phone} alt="phone image" />
                      <div style={{ marginTop: "-5px", marginLeft: "5px" }}>
                        +359 88 634 9979
                      </div>
                    </div>
                    <div className="flex">
                      <img className="h-5 w-5" src={phone} alt="phone image" />
                      <div style={{ marginTop: "-5px", marginLeft: "5px" }}>
                        +359 87 876 3756
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*<a
                href="#"
                className="mt-6 inline-block w-full bg-gray-900 border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-gray-800 sm:w-auto"
              >
                Get access to our one-time sale
               </a>*/}
            </div>
          </section>

          {/* Testimonials */}
        </div>
      </div>

      <main>
        {/* Contact Section */}
        <div className="relative">
          <div className="lg:absolute lg:inset-0">
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <img
                className="h-56 w-full object-cover lg:absolute lg:h-full"
                src={trucks}
                alt=""
                style={{ filter: "brightness(1.3)" }}
              />
            </div>
          </div>
          <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
            <div className="lg:pr-8">
              <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                <h2
                  className="text-3xl font-extrabold tracking-tight sm:text-4xl"
                  style={{
                    color: "rgb(18 57 91)",
                  }}
                >
                  {t("Let's work together")}
                </h2>
                <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                  {t(
                    "We’d love to hear from you! Send us a message using the form opposite, or email us."
                  )}
                </p>
                <form
                  action="#"
                  method="POST"
                  className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                  onSubmit={(e) => sendEmail(e)}
                >
                  <div className="border-b-2 border-medium">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("First name")}
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        style={{ height: "30px" }}
                        className="block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="border-b-2 border-medium">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("Last name")}
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        style={{ height: "30px" }}
                        className="block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="border-b-2 border-medium sm:col-span-2">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("Email")}
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        style={{ height: "30px" }}
                        className="block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="border-b-2 border-medium sm:col-span-2">
                    <div className="flex justify-between">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("Phone")}
                      </label>
                      <span
                        id="phone-description"
                        className="text-sm text-gray-500"
                      >
                        {t("Optional")}
                      </span>
                    </div>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        autoComplete="tel"
                        aria-describedby="phone-description"
                        style={{ height: "30px" }}
                        className="block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="border-b-2 border-medium sm:col-span-2">
                    <div className="flex justify-between">
                      <label
                        htmlFor="how-can-we-help"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("How can we help you?")}
                      </label>
                      <span
                        id="how-can-we-help-description"
                        className="text-sm text-gray-500"
                      >
                        {t("Max. 500 characters")}
                      </span>
                    </div>
                    <div className="mt-1">
                      <textarea
                        required
                        id="how-can-we-help"
                        name="how-can-we-help"
                        aria-describedby="how-can-we-help-description"
                        rows={4}
                        className="block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border border-gray-300 rounded-md"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="text-right sm:col-span-2">
                    <button
                      type="submit"
                      className="border-solid	border-black text-black inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-grape-600 hover:bg-grape-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grape-500"
                    >
                      {t("Submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
