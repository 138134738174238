import React from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "./../styles.scss";
import "./../slideranimation.css";
import test from "../img/test2.jpg";
import test1 from "../img/landing-about/road.jpg";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

export default function NewSlider() {
  const { t } = useTranslation();

  const content = [
    {
      title: t("INTERNATIONAL FRIGO TRANSPORT"),
      description1: t("10+ years of experience in the carriage of goods"),
      description2: t(
        "We are professionals in transport which requires temperature regime"
      ),
      description3: t(
        "Our trailers maintain temperatures from -25 to +25 degrees"
      ),
      // description3: "10+ years of experience",
      // description4: "Reliable team",
      button: t("Read More"),
      //image: "https://i.imgur.com/ZXBtVw7.jpg",
      //image: firstImage,
      image: test1,
      backgroundPosition: "50% 85%",
    },
    {
      title: t("FLEET CONTENTS"),
      description1: t("6 new SCANIA Super R450 2022 trucks"),
      description2: t(
        "6 brand new SCHMITZ semi trailers, 3 doubledecks and 3 bi-temperature"
      ),
      button: t("Read More"),
      //image: "https://i.imgur.com/DvmN8Hx.jpg",
      //backgroundPosition: "90% 50%",
      image: test,
    },
    {
      title: t("INTERNATIONAL FRIGO TRANSPORT"),
      description1: t("10+ years of experience in the carriage of goods"),
      description2: t(
        "We are professionals in transport which requires temperature regime"
      ),
      description3: t(
        "Our trailers maintain temperatures from -25 to +25 degrees"
      ),
      // description3: "10+ years of experience",
      // description4: "Reliable team",
      button: t("Read More"),
      //image: "https://i.imgur.com/ZXBtVw7.jpg",
      //image: firstImage,
      image: test1,
      backgroundPosition: "50% 85%",
    },
    {
      title: t("FLEET CONTENTS"),
      description1: t("6 new SCANIA Super R450 2022 trucks"),
      description2: t(
        "6 brand new SCHMITZ semi trailers, 3 doubledecks and 3 bi-temperature"
      ),
      button: t("Read More"),
      //image: "https://i.imgur.com/DvmN8Hx.jpg",
      //backgroundPosition: "90% 50%",
      image: test,
    },
  ];

  const navigate = useNavigate();

  return (
    <div>
      <div className="wrapper"></div>
      <Slider autoplay={3500} className="slider-wrapper">
        {content.map((item, index) => (
          <div
            key={index}
            className="slider-content bp"
            style={{
              background: ` url('${item.image}') no-repeat center center`,
            }}
          >
            <div className="inner">
              <h1 className="text-3xl lg:text-5xl">{item.title}</h1>
              <p className="font-bold">{item.description1}</p>
              <p>{item.description2}</p>
              <p>{item.description3}</p>

              {item.button ? (
                <button
                  className="button ml-[9%] text-white"
                  onClick={() => navigate("/about")}
                >
                  {item.button}
                </button>
              ) : null}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
