import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  const navigation = {
    main: [
      { name: t("Home"), href: "/" },
      { name: t("Contact us"), href: "/contact" },
      { name: t("Abous us"), href: "/about" },
    ],
  };

  return (
    <footer
      style={{
        backgroundColor: "rgb(18 57 91)",
        //color: "rgb(79 70 229/var(--tw-text-opacity))",
      }}
    >
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8 text-gray-400">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <a href={item.href} className="text-base hover:text-white">
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className="mt-8 flex justify-center space-x-6">
          <span>{t("Telephone")}: +359898429008</span>
          <span>{t("Email")}: krasiautotrans@gmail.com</span>
        </div>
        <p className="mt-8 text-center text-base text-gray-400 font-bold">
          &copy; {t("2022 KRASI AUTO TRANS EOOD. All rights reserved.")}
        </p>
      </div>
    </footer>
  );
}
