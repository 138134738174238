/* This example requires Tailwind CSS v2.0+ */

import { Heading } from "../shared/Heading";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <div className="bg-[#F6F5F5]">
      <Heading />
      <div className="min-h-full flex flex-col">
        <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden"></div>

        <main className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 my-auto py-16 sm:py-32">
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              {t("Page not found")}
            </h1>
            <div className="mt-6">
              <a
                href="/"
                className="text-base font-medium text-indigo-600 hover:text-indigo-500"
              >
                {t("Go back home")}
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </div>
  );
}
