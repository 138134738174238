import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import { useEffect } from "react";
import logo from "../img/aide.png";

export const Heading = () => {
  const languages = [
    {
      code: "fr",
      name: "BG",
      country_code: "bg",
      hasASlash: true,
    },
    {
      code: "en",
      name: "ENG",
      country_code: "gb",
      hasASlash: false,
    },
  ];

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Setting page stuff " + currentLanguage);
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  const displayMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <header>
      <div className="relative bg-[#F6F5F5]">
        <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-2 sm:px-6 md:justify-start md:space-x-10 lg:px-8 ">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <img className="h-[70px]" src={logo} alt="logo" />
            </Link>
            <div as="nav" className="hidden md:flex space-x-10 m-auto">
              <div className="relative">
                {({ open }) => (
                  <>
                    <button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      )}
                    >
                      <span>Solutions</span>
                      <div
                        className={classNames(
                          open ? "text-gray-600" : "text-gray-400",
                          "ml-2 h-5 w-5 group-hover:text-gray-500"
                        )}
                        aria-hidden="true"
                      />
                    </button>
                  </>
                )}
              </div>

              <Link
                className="text-base font-medium hover:underline"
                to="/"
                style={{
                  color: "rgb(18 57 91)",
                  //color: "rgb(79 70 229/var(--tw-text-opacity))",
                }}
              >
                {t("Home")}
              </Link>
              <Link
                className="text-base font-medium  hover:underline"
                to="/contact"
                style={{
                  color: "rgb(18 57 91)",
                  //color: "rgb(79 70 229/var(--tw-text-opacity))",
                }}
              >
                {t("Contact us")}
              </Link>
              <Link
                className="text-base font-medium hover:underline "
                to="/about"
                style={{
                  color: "rgb(18 57 91)",
                  //color: "rgb(79 70 229/var(--tw-text-opacity))",
                }}
              >
                {t("About us")}
              </Link>
            </div>
          </div>
          <div className="flex -mr-2 -my-2 md:hidden">
            <span className="pr-[10px] pt-2">
              {" "}
              <span className="flex">
                {languages.map(({ code, name, country_code, hasASlash }) => (
                  <li className="list-none	" key={country_code}>
                    <a
                      href="#"
                      className={classNames("dropdown-item", {
                        disabled: currentLanguageCode === code,
                      })}
                      onClick={() => {
                        i18next.changeLanguage(code);
                      }}
                    >
                      <span
                        style={{
                          opacity: currentLanguageCode === code ? 0.5 : 1,
                        }}
                      ></span>
                      <span className="pl-[5px] text-[#505050] ">{name}</span>
                      {hasASlash ? <span className="pr-[5px]"> / </span> : null}
                    </a>
                  </li>
                ))}
              </span>
            </span>
            <button
              onClick={() => displayMobileMenu()}
              className="rounded-md p-2 inline-flex items-center justify-center text-[#505050] hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <span className="flex">
              {languages.map(({ code, name, country_code, hasASlash }) => (
                <li className="list-none	" key={country_code}>
                  <a
                    href="#"
                    className={classNames("dropdown-item", {
                      disabled: currentLanguageCode === code,
                    })}
                    onClick={() => {
                      i18next.changeLanguage(code);
                    }}
                  >
                    <span
                      className={`flag-icon flag-icon-${country_code}`}
                      style={{
                        opacity: currentLanguageCode === code ? 0.5 : 1,
                      }}
                    ></span>
                    <span className="pl-[5px] text-[#505050] ">{name}</span>
                    {hasASlash ? <span className="pr-[5px]"> / </span> : null}
                  </a>
                </li>
              ))}
            </span>
          </div>
        </div>
        <div></div>
        {!showMobileMenu ? null : (
          <div className="transition duration-150 ease-in-out	">
            <div className="text-center">
              <Link
                className="text-lg font-bold"
                to="/"
                style={{
                  color: "rgb(18 57 91)",
                  //color: "rgb(79 70 229/var(--tw-text-opacity))",
                }}
              >
                {t("Home")}
              </Link>
            </div>
            <div className="text-center">
              <Link
                className="text-lg font-bold"
                to="/contact"
                style={{
                  color: "rgb(18 57 91)",
                }}
              >
                {t("Contact us")}
              </Link>
            </div>
            <div className="text-center">
              <Link
                className="text-lg font-bold"
                to="/about"
                style={{
                  color: "rgb(18 57 91)",
                }}
              >
                {t("About us")}
              </Link>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};
