import { Heading } from "../shared/Heading";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import test1 from "./../img/about/1.jpg";
import test2 from "./../img/about/2.jpg";
import test3 from "./../img/about/3.jpg";
import test4 from "./../img/about/4.jpg";
import location from "../img/shipment.svg";

/* This example requires Tailwind CSS v2.0+ */

export default function AboutUsPage() {
  const { t } = useTranslation();
  const features = [
    {
      name: t("Controlled temperature"),
      description: t(
        "Our trucks are EURO 6. They also have thermographs that record the temperature of your cargo throughout the transport. The condition of your goods is monitored and controlled from the first to the last minute."
      ),
    },
    {
      name: t("Two-temperature loads"),
      description: t(
        "Our refrigerated semi-trailers have partition walls for transporting two-temperature loads (+ 25C / -25C)."
      ),
    },
    {
      name: t("Equipment"),
      description: t(
        "All vehicles are equipped with a satellite GPS tracking system."
      ),
    },
    /* {
      name: "Finish",
      description:
        "Ремаркетата разполагат с термографи, отчитащи температурата на товарa през цялото време на транспортиране.",
    },*/
    {
      name: t("Transportation time"),
      description: t("We offer optimal time for transportation of your goods."),
    },
    /*{
      name: t("The team"),
      description: t("A team with over 20 years of experience."),
    },*/
  ];

  const incentives = [
    {
      name: t("Fleet of vehicles"),
      imageSrc:
        "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
      description: t(
        "We try to stay on top, which means that we use a modern fleet of vehicles."
      ),
    },
    {
      name: t("Certificated and Insured"),
      imageSrc:
        "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
      description: t("FRC certificate, ATP certificate, CMR insurance"),
    },
    {
      name: t("The team"),
      imageSrc: location,
      description:
        t("Our team has over 20 years of experience. Together we have made over 2000 shipments possible."),
    },
    /*{
      name: "Exchanges",
      imageSrc:
        "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
      description:
        "If you don't like it, trade it to one of your friends for something of theirs. Don't send it here though.",
    },*/
  ];

  return (
    <div className="bg-[#F6F5F5]">
      <Heading />

      <main>
        {/* Sale and testimonials */}
        <div className="relative overflow-hidden">
          {/* Decorative background image and gradient */}
          <div aria-hidden="true" className="absolute inset-0">
            <div className="absolute inset-0 mx-auto overflow-hidden">
              <img
                src={test1}
                alt=""
                className="w-full h-full object-center object-cover"
              />
            </div>
            <div className="absolute inset-0 bg-white bg-opacity-75" />
            <div className="absolute inset-0 bg-gradient-to-t from-white via-white" />
          </div>

          {/* Sale */}
          <section
            aria-labelledby="sale-heading"
            className="relative max-w-7xl mx-auto pt-32 pb-24 px-4 flex flex-col items-center text-center sm:px-6 lg:px-8"
          >
            <div className="max-w-2xl mx-auto lg:max-w-none">
              <h2
                id="sale-heading"
                className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl"
                style={{
                  color: "rgb(18 57 91)",
                }}
              >
                {t("A family business which prefers quality over quantity")}
              </h2>
              <p className="mt-6 max-w-2xl mx-auto text-xl text-gray-600 pl-6 pr-6">
                {t(
                  "The transport company Krasi Auto Trans EOOD, created in 2016, is a family business which is focused on creating open and honest relationships. We are commited and work all days of the week."
                )}
                <br />
                {t(
                  "With previous experience in the transportation industry and 6 brand new SCHMITZ semi trailers, 3 doubledecks and 3 bi-temperature - we are professionals in transport which requires temperature regime."
                )}
              </p>
            </div>
          </section>
        </div>

        <div className="bg-[#F6F5F5] max-w-2xl mx-auto py-24 px-4 grid items-center grid-cols-1 gap-y-16 gap-x-8 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:grid-cols-2">
          <div>
            <h2
              className="text-3xl font-extrabold tracking-tight sm:text-4xl"
              style={{
                color: "rgb(18 57 91)",
                //color: "rgb(79 70 229/var(--tw-text-opacity))",
              }}
            >
              {t("More about us")}
            </h2>

            <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
              {features.map((feature) => (
                <div key={feature.name} className="border-t border-gray-200">
                  <dt
                    className="font-bold"
                    style={{
                      color: "rgb(18 57 91)",
                    }}
                  >
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
            <img
              src={test1}
              alt="truck-image"
              className="bg-gray-100 m-auto rounded-lg lg:w-[213px] lg:h-[213px] object-cover	"
            />
            <img
              src={test2}
              alt="truck-image"
              className="bg-gray-100 m-auto rounded-lg lg:w-[213px] lg:h-[213px] object-cover"
            />
            <img
              src={test3}
              alt="truck-image"
              className="bg-gray-100 m-auto rounded-lg lg:w-[213px] lg:h-[213px] object-cover"
            />
            <img
              src={test4}
              alt="truck-image"
              className="bg-gray-100 m-auto rounded-lg lg:w-[213px] lg:h-[213px] object-coverr"
            />
          </div>
        </div>
        <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
          <div className="mt-4 mb-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
            {incentives.map((incentive, index) => (
              <div
                key={incentive.name}
                className="sm:flex lg:block pb-4"
                style={{ borderBottom: "3px solid #4682b4" }}
              >
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src={incentive.imageSrc} alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3
                    className="text-sm font-bold"
                    style={{
                      color: "rgb(18 57 91)",
                    }}
                  >
                    {incentive.name}
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    {incentive.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
