import { Heading } from "../shared/Heading";
import NewSlider from "./TestPage";
import StatCards from "./Stats";
import firstImg from "./../img/landing-about/first.jpg";
import maps from "./../img/landing-about/maps.jpg";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import location1 from "../img/shipment.svg";
import testwe from "./../img/about/2.jpg";
import next from "./../img/landing-about/next.svg";
import { useNavigate } from "react-router-dom";

export default function Example() {
  const { t } = useTranslation();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const HeadingBulletPoint = (props) => {
    return (
      <div className="mb-2 text-gray-500 font-medium flex m-auto">
        <p className="text-center pl-4">✔{props.children}</p>
      </div>
    );
  };

  const more = [
    {
      name: t("CMR and cargo insurance"),
      description: t(
        "We offer transport services at a professional level, speed, accuracy and correctness based on mutual respect in our relationships with our customers and partners. We have our own parking lot, and the CMR and cargo insurance for each means of transport ensure the security of the transported goods."
      ),
      //imageSrc:
      //"https://tailwindui.com/img/ecommerce-images/product-feature-07-detail-01.jpg",
      // imageSrc: market,
      imageSrc: firstImg,
      style: "float-right",
      imageAlt:
        "White canvas laptop sleeve with gray felt interior, silver zipper, and tan leather zipper pull.",
    },
    {
      name: t("Established in 2016"),
      description: t(
        "Established in Plovdiv back in 2016, Krasi Auto Trans built a reputation of being a trusted cargo carrier capable of meeting the expectations of its clients. Our company deals exclusively with transportation and carriage of goods in Bulgaria and all over Europe."
      ),
      //imageSrc:
      //"https://tailwindui.com/img/ecommerce-images/product-feature-07-detail-02.jpg",
      imageSrc: testwe,
      imageAlt: "Detail of zipper pull with tan leather and silver rivet.",
      style: "float-left",
    },
  ];
  const navigate = useNavigate();

  return (
    <div className="bg-[#F6F5F5]">
      <Heading />
      <main>
        <NewSlider />

        {/* Logo Cloud */}
        <div className="bg-[#F6F5F5] max-w-2xl mx-auto py-24 px-4 sm:px-6 sm:py-20 lg:max-w-7xl lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2
              className="text-3xl font-extrabold tracking-tight sm:text-4xl"
              style={{
                color: "rgb(18 57 91)",
                //color: "rgb(79 70 229/var(--tw-text-opacity))",
              }}
            >
              {t("About Us")}
            </h2>
            <p className="mt-4 text-gray-500 pl-4 pr-4">
              {t(
                "Krasi Auto Trans is a licensed for international transport services company located in Plovdiv 4004, Kuklensko shose 7A."
              )}
              <br />
              <br />
              <span className="flex">
                <HeadingBulletPoint>
                  {t("6 brand new frigo semi trailers")}
                </HeadingBulletPoint>
                <HeadingBulletPoint>
                  {t("3 doubledecks and 3 bi-temperature")}
                </HeadingBulletPoint>
              </span>
              <span className="flex">
                <HeadingBulletPoint>{t("FRC certificate")}</HeadingBulletPoint>
                <HeadingBulletPoint>
                  {t("CMR insurance covering up to 1 000 000 euro")}
                </HeadingBulletPoint>
                <HeadingBulletPoint>{t("ATP certificate")}</HeadingBulletPoint>
              </span>
              <br />
              {/*} {t(
                "Detailed information about services, prices, requirements, where and what we drive you can read in the relevant section, as well as contact us on the phones listed on our contact page."
             )}*/}
            </p>

            {/*}<button className="button text-white">{t("More about us")}</button>)}*/}
          </div>

          <div className="mt-12 space-y-14">
            {more.map((feature, featureIdx) => (
              <div
                key={feature.name}
                className="max-w-[80%] m-auto flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center"
              >
                <div
                  className={classNames(
                    featureIdx % 2 === 0
                      ? "lg:col-start-1"
                      : "lg:col-start-8 xl:col-start-9",
                    "mt-6 lg:mt-0 lg:row-start-1 lg:col-span-5 xl:col-span-4"
                  )}
                >
                  <h3
                    className="text-lg font-bold"
                    style={{
                      color: "rgb(18 57 91)",
                      fontSize: "20px",
                    }}
                  >
                    {feature.name}
                  </h3>
                  <p
                    className="mt-2 text-sm text-gray-500"
                    style={{ fontSize: "16px" }}
                  >
                    {feature.description}
                  </p>
                </div>
                <div
                  className={classNames(
                    featureIdx % 2 === 0
                      ? "lg:col-start-6 xl:col-start-5"
                      : "lg:col-start-1",
                    "flex-auto lg:row-start-1 lg:col-span-7 xl:col-span-8"
                  )}
                >
                  <div
                    className={`${feature.style} w-[60%] aspect-w-5 aspect-h-2 rounded-lg bg-gray-100 overflow-hidden`}
                  >
                    <img
                      src={feature.imageSrc}
                      alt={feature.imageAlt}
                      className="object-center object-cover"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <StatCards />
        <a
          className="home"
          href="https://www.google.com/maps/place/bul.+%22Kuklensko+Shose%22+7,+4030+g.k.+Hristo+Botev-North,+Plovdiv/@42.1293064,24.7558248,17z/data=!3m1!4b1!4m5!3m4!1s0x14acd0537d5bcfb1:0xf7ec6c49ee9de95a!8m2!3d42.1293064!4d24.7558248"
          target="_blank"
          rel="noopener"
        >
          <img
            src={maps}
            alt="maps"
            style={{ maxHeight: "230px", width: "100%", objectFit: "cover" }}
          />
          <p className="centered text-4xl flex">
            {t("Our location")}{" "}
            <span>
              <img
                className="next-to-centered"
                src={next}
                style={{ width: "1rem", color: "white" }}
              />
            </span>
          </p>
        </a>
      </main>
      <Footer />
    </div>
  );
}
