import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import AboutUsPage from "./pages/AboutUsPage";
import ContactPage from "./pages/ContactPage";
import { Home } from "./pages/Home";
import Example from "./pages/LandingPage";
import NotFound from "./pages/NotFound";
import { Heading } from "./shared/Heading";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Example />} />
        <Route exact path="/contact" element={<ContactPage />} />
        <Route exact path="/about" element={<AboutUsPage />} />
        <Route path=":any" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
